import * as Yup from 'yup';

export interface DocumentFormValues {
    fileName: string;
    newPath: string;
    tags: string;
    file: File | null;
}

export const documentValidationSchema = Yup.object({
    file: Yup.mixed().nullable().required('A file is required'),
    newPath: Yup.string().required('New Folder name is required'),
    fileName: Yup.string().required('File name is required'),
    tags: Yup.string(),
});

export const editorModeList: string[] = [
    'Editor',
    'Administrator',
    'SuperAdministrator',
];

export const deleteModeList: string[] = [
    'Administrator',
    'SuperAdministrator',
]