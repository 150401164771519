import { Viewport, CursorPositionProps } from "../pages/map-view-page/MapViewLib";
import { MapRef } from 'react-map-gl';
import { SetStateAction, Dispatch, MutableRefObject } from 'react';
import sensor from '../asset/Icon/station/water quality/waterquality.png'
import nutrient from '../asset/Icon/station/nutrient survey/nutrientsurvey.png'
import plankton from '../asset/Icon/station/plankton survey/planktonsurvey.png'
import sediment from '../asset/Icon/station/sediment quality/sedimentquality.png'
import mapboxgl from 'mapbox-gl';

type VisibilityState = { [key: string]: boolean };

// map view - layer collapse
export const handleGroupClick = (
    group: any,
    groupId: string,
    setOpen: Dispatch<SetStateAction<{ [key: string]: boolean }>>,
    setDataType: (val: string) => void,
    setDataTypeLabel: (val: string) => void,
    fetchedLayers: MutableRefObject<{ [key: string]: boolean }>,
    fetchedStations: MutableRefObject<{ [key: string]: boolean }>,
    fetchLayer: (layerId: string, subGroupId: string) => void,
    setIsShowFishFarms: (val: boolean) => void,
    isShowFishFarms: boolean,
    fetchStationData: (val: string) => void
) => {
    setOpen(prev => ({ ...prev, [groupId]: !prev[groupId] }));
    setDataType(group.dataType);
    setDataTypeLabel(group.name);

    if (group.dataType === 'FishFarms') {
        setIsShowFishFarms(!isShowFishFarms);
    } else {
        group.subGroups?.forEach((subGroup: any, index: number) => {
            const subGroupId = `subGroup-${group.id}-${index}`;
            if (!fetchedLayers.current[subGroupId]) {
                fetchedLayers.current[subGroupId] = true;
                fetchLayer(subGroup.layerId, subGroupId);
            }
        });

        if (!fetchedStations.current[group.dataType]) {
            fetchedStations.current[group.dataType] = true;
            fetchStationData(group.dataType);
        }
    }
};

export const handleSubGroupClick = (
    layerId: string,
    subGroupId: string,
    setExpandedSubGroups: Dispatch<SetStateAction<{ [key: string]: boolean }>>,
    layersData: any,
    fetchedLayers: MutableRefObject<{ [key: string]: boolean }>,
    fetchLayer: (layerId: string, subGroupId: string) => void
) => {
    setExpandedSubGroups(prev => ({ ...prev, [subGroupId]: !prev[subGroupId] }));
    if (!layersData[subGroupId] && !fetchedLayers.current[subGroupId]) {
        fetchedLayers.current[subGroupId] = true;
        fetchLayer(layerId, subGroupId);
    }
};

export const handleClickLayer = (
    item: any,
    setPopupInfo: Dispatch<SetStateAction<any>>,
    setSelectedStation: Dispatch<SetStateAction<any>>,
    setSelectedLayer: (val: string | null) => void,
    fetchStationById: (val: string) => void,
    stationInfo: any,
    setStationInfo: (val: any) => void,
    dataType: string
) => {
    setPopupInfo(null);
    setSelectedStation(null);
    setSelectedLayer(item.properties.Name);
    fetchStationById(item.properties.Name)
    setStationInfo({
        ...stationInfo,
        selectedDataType: dataType,
        selectedStation: [item.properties.Name],
        selectedParameters: [],
        selectedArea: '',
    })
};

export const handleClickTimeslider = (
    e: { stopPropagation: () => void; },
    group: any,
    dataType: string,
    isShowTimeSlider: boolean,
    setIsShowTimeSlider: (val: boolean) => void,
    fetchAvailableDates: (dataType: string) => void
) => {
    if (group.dataType !== 'WaterQuality') {
        if (group.dataType !== dataType) {
            setIsShowTimeSlider(false);
        } else {
            setIsShowTimeSlider(!isShowTimeSlider);
        }
        fetchAvailableDates(group.dataType);
    }
};

export const handleHighlightLayer = (
    stationIds: string[],
    setHighlightLayer: React.Dispatch<React.SetStateAction<string[]>>,
    isMultipleSelection: boolean
) => {
    setHighlightLayer((prev) => {
        if (isMultipleSelection) {
            return stationIds;
        } else {
            return stationIds.length > 0 ? [stationIds[0]] : [];
        }
    });
};

export const handleVisibility = (
    featureName: string,
    setVisibility: Dispatch<SetStateAction<VisibilityState>>,
    setPopupInfo: Dispatch<SetStateAction<any>>
) => {
    setVisibility(prev => {
        const newVisibility = { ...prev, [featureName]: !prev[featureName] };
        return newVisibility;
    });
    setPopupInfo(null);
};

export const handleGroupVisibility = (
    group: any,
    layersData: any,
    setVisibility: Dispatch<SetStateAction<VisibilityState>>,
    visibility: VisibilityState,
    setPopupInfo: Dispatch<SetStateAction<any>>,
    isShowTimeslider: boolean,
    setIsShowTimeSlider: (val: boolean) => void,
) => {
    const subGroups = getSubgroups(group);
    const allVisible = subGroups.every((subGroup: any, index: number) => {
        const subGroupId = `subGroup-${group.id}-${index}`;
        const subGroupLayers = layersData[subGroupId] || [];
        return subGroupLayers.every((item: any) => visibility[item.properties.Name]);
    });

    setVisibility(prev => {
        const newVisibility = { ...prev };
        subGroups.forEach((subGroup: any, index: number) => {
            const subGroupId = `subGroup-${group.id}-${index}`;
            const subGroupLayers = layersData[subGroupId] || [];
            subGroupLayers.forEach((item: any) => {
                newVisibility[item.properties.Name] = !allVisible;
            });
        });
        if (newVisibility) {
            setIsShowTimeSlider(false)
        }
        return newVisibility;
    });

    setPopupInfo(null);
};

export const handleSubGroupVisibility = (
    subGroupId: string,
    layersData: any,
    setVisibility: Dispatch<SetStateAction<VisibilityState>>,
    setPopupInfo: Dispatch<SetStateAction<any>>
) => {
    setVisibility(prev => {
        const newVisibility = { ...prev };
        const subGroupLayers = layersData[subGroupId] || [];
        const allVisible = subGroupLayers.every((item: any) => prev[item.properties.Name]);

        subGroupLayers.forEach((item: any) => {
            newVisibility[item.properties.Name] = !allVisible;
        });

        return newVisibility;
    });
    setPopupInfo(null);
};

export const handleSubGroupZoom = (
    subGroupId: string,
    layersData: any,
    mapRef: MutableRefObject<MapRef | null>
) => {
    const subGroupLayers = layersData[subGroupId] || [];
    if (subGroupLayers.length > 0 && mapRef.current) {
        const bounds = new mapboxgl.LngLatBounds();

        if (subGroupLayers.length === 1) {
            subGroupLayers.forEach((item: any) => {
                const [lng, lat] = item.geometry.coordinates;
                bounds.extend([lng, lat]);
            });
            mapRef.current.fitBounds(bounds, { zoom: 13 });
        } else {
            subGroupLayers.forEach((item: any) => {
                const [lng, lat] = item.geometry.coordinates;
                bounds.extend([lng, lat]);
            });
            mapRef.current.fitBounds(bounds, { zoom: 14 });
        }
    }
};

export const handleFishFarmsGroup = (
    mapRef: MutableRefObject<MapRef | null>,
    fishFarm: any
) => {
    if (mapRef.current && fishFarm) {
        const bounds = new mapboxgl.LngLatBounds();
        fishFarm.features.forEach((farm: any) => {
            farm.geometry.coordinates[0].forEach(([lng, lat]: any) => {
                bounds.extend([lng, lat]);
            });
        });
        mapRef.current.fitBounds(bounds, { padding: 100 });
    }
};

export const isGroupVisible = (
    group: any,
    layersData: any,
    visibility: VisibilityState
): boolean => {
    const subGroups = getSubgroups(group);
    return subGroups.every((subGroup: any, index: number) => {
        const subGroupId = `subGroup-${group.id}-${index}`;
        const subGroupLayers = layersData[subGroupId] || [];
        return subGroupLayers.every((item: any) => visibility[item.properties.Name]);
    });
};

export const isSubGroupVisible = (
    subGroupId: string,
    layersData: any,
    visibility: VisibilityState
): boolean => {
    const subGroupLayers = layersData[subGroupId] || [];
    return subGroupLayers.every((item: any) => visibility[item.properties.Name]);
};

export const fetchFishFarmLayer = (fetchLayer: (layerId: string, subGroupId: string) => void) => {
    fetchLayer('FishFarms', 'fish-farms-layer');
};

export const getSubgroups = (group: any): any[] => {
    return group.subGroups || group.subgroups || [];
};

export const getValidStationIds = (stationData: any) => {
    const stationIds = new Set();
    Object.values(stationData).forEach((group: any) => {
        group.forEach((feature: any) => {
            stationIds.add(feature.properties.Name);
        });
    });
    return stationIds;
};

// map - DeckGLMap
export const handleMouseMove = (
    event: { point: { x: number; y: number } },
    mapRef: React.RefObject<MapRef>,
    setCursorPosition: Dispatch<SetStateAction<CursorPositionProps>>
): void => {
    if (mapRef.current) {
        const lngLat = mapRef.current.getMap().unproject([event.point.x, event.point.y]);
        const latitude = lngLat.lat;
        const longitude = lngLat.lng;

        setCursorPosition({ latitude, longitude });
    }
};

// map - station icon
const styles: { [key: string]: React.CSSProperties } = {
    online: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid green',
        backgroundColor: 'white',
        width: 30,
        height: 30,
        borderRadius: '50%',
        padding: '2px',
        filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6))',
    },
    offline: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid grey',
        backgroundColor: 'white',
        width: 30,
        height: 30,
        borderRadius: '50%',
        padding: '2px',
        filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6))',
    },
    onlineWarning: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid green',
        backgroundColor: 'red',
        width: 30,
        height: 30,
        borderRadius: '50%',
        padding: '2px',
        filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6))',
    },
    offlineWarning: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid grey',
        backgroundColor: 'red',
        width: 30,
        height: 30,
        borderRadius: '50%',
        padding: '2px',
        filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6))',
    }
};

export const getIcon = (dataType: string) => {
    switch (dataType) {
        case 'WaterQuality':
            return sensor;
        case 'NutrientSurveyResult':
            return nutrient;
        case 'PlanktonSurvey':
            return plankton;
        case 'SedimentQuality':
            return sediment;
        default:
            return sensor;
    }
};

export const getIconStyle = (isOnline: boolean, isWithinThreshold: boolean, isSelected: boolean) => {
    let baseStyle;
    if (isOnline) {
        baseStyle = isWithinThreshold ? styles.online : styles.onlineWarning;
    } else {
        baseStyle = isWithinThreshold ? styles.offline : styles.offlineWarning;
    }
    return {
        ...baseStyle,
        boxShadow: isSelected ? '0 0 5px 5px yellow' : baseStyle.boxShadow,
    };
};

// map - window query parameter images
export const getSafeImage = (path: string, name: string) => {
    try {
        return require(`../asset/Icon/Popup/${path}/${name.replace(/ /g, '').toLowerCase()}.svg`);
    } catch {
        return require(`../asset/Icon/Popup/${path}/default.svg`);
    }
};

// map - navigation control
export const zoomIn = (viewport: Viewport, setViewport: (val: Viewport) => void) => {
    setViewport({
        ...viewport,
        zoom: viewport.zoom + 1,
    });
}

export const zoomOut = (viewport: Viewport, setViewport: (val: Viewport) => void) => {
    setViewport({
        ...viewport,
        zoom: viewport.zoom - 1,
    });
}

export const handleFullscreen = (mapContainer: HTMLElement) => {
    if (!document.fullscreenElement) {
        mapContainer.requestFullscreen().catch((e) => console.log(e));
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen().catch((e) => console.log(e));
        }
    }
};

// calculate left custom popup
export const calculateLeftPosition = (showMenu: boolean, screenSize: string) => {
    if (!showMenu) return '0px'
  
    switch (screenSize) {
      case 'xs':
        return '80vw'
      case 'sm':
        return '300px'
      case 'md':
        return '350px'
      case 'lg':
        return '400px'
      default:
        return '450px'
    }
  }