// src/components/DocumentListView/FileRow.tsx
import React from 'react';
import { TableCell, TableRow, Box, TextField, IconButton, Tooltip } from '@mui/material';
import { InsertDriveFile as InsertDriveFileIcon, Download, ModeEdit, Clear, Save, Cancel } from '@mui/icons-material';
import { formatDate, formatSize } from '../../../utils/Utils';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { deleteModeList, editorModeList } from './DocumentData';

interface FileRowProps {
    file: any;
    editing: boolean;
    editFileId: any;
    editFileName: string;
    setEditFileName: (name: string) => void;
    handleSaveClick: () => void;
    handleCancelClick: () => void;
    handleEditClick: (file: any) => void;
    handleClickClearButton: (id: any) => void;
    downLoadDocument: (id: any) => void;
}

const FileRow: React.FC<FileRowProps> = ({
    file,
    editing,
    editFileId,
    editFileName,
    setEditFileName,
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleClickClearButton,
    downLoadDocument,
}) => {
    const { user } = useAuth();

    const hasEditPermission = Array.isArray(user?.roles)
        ? user.roles.some((role: string) => editorModeList.includes(role))
        : editorModeList.includes(user?.roles as string);

    const hasDeletePermission = Array.isArray(user?.roles)
        ? user.roles.some((role: string) => deleteModeList.includes(role))
        : deleteModeList.includes(user?.roles as string);

    return (
        <TableRow>
            <TableCell component="th" scope="row" style={{ paddingLeft: `10px` }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <InsertDriveFileIcon style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                    {editing && editFileId === file.id ? (
                        <TextField
                            value={editFileName}
                            onChange={(e) => setEditFileName(e.target.value)}
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    '& .MuiInputBase-input': {
                                        fontSize: 12,
                                        margin: 0,
                                        padding: 0,
                                        height: 30,
                                    },
                                    '& .MuiFilledInput-root': {
                                        '&:before': {
                                            borderBottom: 'none',
                                        },
                                        '&:after': {
                                            borderBottom: 'none',
                                        },
                                        '&:hover:not(.Mui-disabled):before': {
                                            borderBottom: 'none',
                                        },
                                    },
                                },
                            }}
                        />
                    ) : (
                        file.name
                    )}
                </Box>
            </TableCell>
            <TableCell align="right">{formatSize(file.fileSize)}</TableCell>
            <TableCell align="right">{formatDate(file.modifiedDateTime)}</TableCell>
            <TableCell align="right">
                {editing && editFileId === file.id ? (
                    <Box sx={{ minWidth: 120 }}>
                        <Tooltip title="Save">
                            <IconButton aria-label="save" size="small" onClick={handleSaveClick}>
                                <Save />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                            <IconButton aria-label="cancel" size="small" onClick={handleCancelClick}>
                                <Cancel />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : (
                    <Box sx={{ minWidth: 120 }}>
                        <Tooltip title="Download">
                            <IconButton aria-label="download" size="small" onClick={() => downLoadDocument(file.id)}>
                                <Download />
                            </IconButton>
                        </Tooltip>
                        {hasEditPermission && (
                            <Tooltip title="Rename">
                                <IconButton aria-label="edit" size="small" onClick={() => handleEditClick(file)}>
                                    <ModeEdit />
                                </IconButton>
                            </Tooltip>
                        )}
                        {hasDeletePermission && (
                            <Tooltip title="Delete">
                                <IconButton aria-label="delete" size="small" onClick={() => handleClickClearButton(file.id)}>
                                    <Clear sx={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

export default FileRow;
