import React, { useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, SelectChangeEvent } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { useTimeseriesView } from '../../../context/timeseries-context/timeseriesContext';
import { useChartOptions } from '../../../hooks/useChartOptions';
import { handleAreaChange, handleDataTypeChange, handleParameterChange, handlePeriodChange, handleStationChange, updateDates } from '../../../utils/TimeseriesUtils';
import { useAuth } from '../../../context/auth-context/AuthContext';
import {
    PeriodSelector,
    DataTypeSelector,
    AreaSelector,
    StationSelector,
    ParameterSelector,
    StatisticTypeSelector
} from './Selectors';
import { getDataTypeData } from './TimeseriesData';
import { useMapView } from '../../../context/map-context/mapContext';
import { handleHighlightLayer } from '../../../utils/MapUtils';
import { showSnackbar } from '../../../utils/Utils';

const TimeseriesPanel: React.FC<{ panelType: 'Observation' | 'Monthly' }> = ({ panelType }) => {
    
    const { baseUrl, user, setVariant, setSnackbarOpen, setSnackbarMessage } = useAuth();
    const { setHighlightLayer } = useMapView()
    const {
        stationInfo,
        fetchObservationsData,
        fetchMonthlyStatisticData,
        exportObservationData,
        exportMonthlyStatisticData,
        fetchAreaList,
        fetchStationList,
        fetchParameterList,
        fetchStatisticTypeList,
        fetchDataTypeList,
        loading,
        dataTypeList,
        areaList,
        stationList,
        parameterList,
        statisticTypeList,
    } = useTimeseriesView();

    const [data, setData] = useState<any>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<string>(panelType === 'Observation' ? (stationInfo?.selectedDataType === 'WaterQuality' ? 'Last 24 Hours' : 'Last 1 Year') : 'Last 1 Year');
    const [selectedDataType, setSelectedDataType] = useState<string>(stationInfo?.selectedDataType);
    const [selectedArea, setSelectedArea] = useState<string>(stationInfo?.selectedArea);
    const [selectedStation, setSelectedStation] = useState<string[]>(stationInfo?.selectedStation);
    const [selectedParameters, setSelectedParameters] = useState<string[]>(stationInfo?.selectedParameters);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [selectedStatisticType, setSelectedStatisticType] = useState<number>(0);
    const [showThreshold, setShowThreshold] = useState(false);
    const [lastStartDate, setLastStartDate] = useState<string>('');
    const [lastEndDate, setLastEndDate] = useState<string>('');
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const [isExportTriggered, setIsExportTriggered] = useState(false);
    const [isRawExportTriggered, setIsRawExportTriggered] = useState(false);

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (baseUrl && user?.token) {
            fetchDataTypeList();
            fetchStationList('WaterQuality', '')
        }
    }, [baseUrl, user?.token]);

    useEffect(() => {
        if (selectedPeriod) {
            updateDates(selectedPeriod, lastStartDate, lastEndDate, setStartDate, setEndDate);
        }
    }, [selectedPeriod, lastStartDate, lastEndDate]);

    useEffect(() => {
        fetchStatisticTypeList(selectedDataType)
        if (selectedDataType !== 'WaterQuality') {
            fetchAreaList(selectedDataType);
            setData([]);
        }
    }, [selectedDataType]);

    useEffect(() => {
        const area = selectedDataType === 'WaterQuality' ? '' : selectedArea;
        fetchStationList(selectedDataType, area);
        setData([]);
    }, [selectedDataType, selectedArea]);

    useEffect(() => {
        if (selectedStation.length > 0) {
            fetchParameterList(selectedStation);
        } else {
            setData([]);
        }
    }, [selectedStation]);

    useEffect(() => {
        if (selectedParameters.length === 0) {
            setData([]);
        }
    }, [selectedParameters]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            const timeoutId = setTimeout(() => {
                if (startDate && endDate && selectedDataType && selectedStation.length > 0 && selectedParameters.length > 0) {
                    const selectedDataTypeItem = dataTypeList.find((item: any) => item.name === selectedDataType);
                    if (panelType === 'Observation') {
                        fetchObservationsData(startDate, endDate, selectedDataTypeItem?.name, selectedStation, selectedParameters, setData);
                    } else {
                        fetchMonthlyStatisticData(startDate, endDate, selectedDataTypeItem?.name, selectedStation, selectedParameters, selectedStatisticType, setData);
                    }
                    setTriggerUpdate(false);
                } else {
                    setData([]);
                }
            }, 0);

            return () => clearTimeout(timeoutId);
        }
    }, [startDate, endDate, selectedStation, selectedParameters, selectedStatisticType, triggerUpdate, showThreshold]);

    useEffect(() => {
        setTriggerUpdate(true);
    }, [showThreshold]);

    useEffect(() => {
        if (isExportTriggered) {
            handleExportData();
            setIsExportTriggered(false);
        }
    }, [isExportTriggered]);

    useEffect(() => {
        if (isRawExportTriggered) {
            handleExportRawData();
            setIsRawExportTriggered(false);
        }
    }, [isRawExportTriggered]);

    const handleApplyClick = () => {
        if (startDate && endDate) {
            const formattedStartDate = startDate.substring(0, 10) + 'T00:00:00';
            const formattedEndDate = endDate.substring(0, 10) + 'T23:59:59';
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
            setLastStartDate(formattedStartDate);
            setLastEndDate(formattedEndDate);
            setTriggerUpdate(true);
        }
    };

    const handleExportData = () => {
        if (!selectedPeriod) return;

        if (panelType === 'Observation') {
            exportObservationData(selectedPeriod, selectedDataType, selectedStation, selectedParameters, false);
        } else {
            exportMonthlyStatisticData(selectedPeriod, selectedDataType, selectedStation, selectedParameters, selectedStatisticType);
        }
    };

    const handleExportRawData = () => {
        if (!selectedPeriod) return;

        exportObservationData(selectedPeriod, selectedDataType, selectedStation, selectedParameters, true);
    };

    const triggerExport = () => {
        setIsExportTriggered(true);
    };

    const triggerRawExport = () => {
        setIsRawExportTriggered(true);
    };

    const {
        getOption
    } = useChartOptions(
        data,
        selectedDataType,
        selectedStation,
        selectedParameters,
        showThreshold,
        setShowThreshold,
        triggerExport,
        triggerRawExport
    );

    const filteredDataTypeList = getDataTypeData(user)

    return (
        <Box sx={{ width: '100%', flexWrap: 'wrap' }}>
            <PeriodSelector
                panelType={panelType}
                selectedPeriod={selectedPeriod}
                handlePeriodChange={(event: SelectChangeEvent) => 
                    handlePeriodChange(
                        event, 
                        setSelectedPeriod, 
                        () => updateDates(selectedPeriod, lastStartDate, lastEndDate, setStartDate, setEndDate)
                    )}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                handleApplyClick={handleApplyClick}
            />
            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" gap={1} flexWrap={'wrap'}>
                <DataTypeSelector
                    selectedDataType={selectedDataType}
                    handleDataTypeChange={(event: SelectChangeEvent) =>
                        handleDataTypeChange(
                            event,
                            setSelectedDataType,
                            setSelectedStation,
                            setSelectedParameters
                        )}
                    filteredDataTypeList={filteredDataTypeList}
                />
                {selectedDataType !== 'WaterQuality' && (
                    <AreaSelector
                        selectedArea={selectedArea}
                        handleAreaChange={(event: SelectChangeEvent) =>
                            handleAreaChange(event, setSelectedArea)}
                        areaList={areaList}
                    />
                )}
                {(selectedDataType === 'WaterQuality' || selectedArea) && (
                    <StationSelector
                        selectedStation={selectedStation}
                        handleStationChange={(event: SelectChangeEvent) =>
                            handleStationChange(
                                event,
                                selectedStation,
                                setSelectedStation,
                                setHighlightLayer,
                                handleHighlightLayer)

                        }
                        stationList={stationList}
                    />
                )}
                {(selectedStation.length > 0) && (
                    <ParameterSelector
                        panelType={panelType}
                        selectedParameters={selectedParameters}
                        handleParameterChange={(event: SelectChangeEvent<string[]>) =>
                            handleParameterChange(
                                event,
                                selectedParameters,
                                setSelectedParameters,
                                setVariant,
                                setSnackbarOpen,
                                setSnackbarMessage,
                                showSnackbar
                            )
                        }
                        parameterList={parameterList}
                    />
                )}
                {panelType === 'Monthly' && selectedParameters.length > 0 && (
                    <StatisticTypeSelector
                        selectedStatisticType={selectedStatisticType}
                        setSelectedStatisticType={setSelectedStatisticType}
                        statisticTypeList={statisticTypeList}
                    />
                )}
            </Box>
            <Box position="relative">
                {loading && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        zIndex={5}
                        bgcolor="rgba(255, 255, 255, 0.7)"
                    >
                        <CircularProgress />
                    </Box>
                )}
                <ReactECharts
                    key={`${data.length}-${showThreshold}`}
                    option={getOption() || {}}
                    style={{
                        height: '550px',
                        width: '100%',
                        border: '1px solid lightgray',
                        borderRadius: 2,
                        padding: 2
                    }}
                />
            </Box>
        </Box>
    );
};

export default TimeseriesPanel;