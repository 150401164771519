import { Alert, Box, Snackbar, useMediaQuery } from '@mui/material';
import React from 'react';
import fishFarm from '../../asset/Images/fish-farm.png';
import UploadMenu from '../../components/layout/upload-content/UploadMenu';
import { useAuth } from '../../context/auth-context/AuthContext';

const UploadPage = () => {

  const { snackbarOpen, setSnackbarOpen, snackbarMessage, variant } = useAuth();
  const alertSeverity: 'success' | 'info' | 'warning' | 'error' = (['success', 'info', 'warning', 'error'].includes(variant) ? variant : 'info') as 'success' | 'info' | 'warning' | 'error';
  const isTabletOrDesktop = useMediaQuery('(min-width: 1150px)');

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100vh',
        backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%), url(${fishFarm})`,
        backgroundPosition: 'top left',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: 0,
        top: 0,
        left: 0,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: isTabletOrDesktop ? '120px 50px 50px 50px' : '90px 20px 20px 20px',
      }}
    >
      <UploadMenu />
      {snackbarOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100
          }}
        >
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            sx={{ position: 'relative' }}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={alertSeverity}
              variant="filled"
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </Box>
  );
}

export default UploadPage;
