import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { MyNewJwtPayload, useAuth } from '../../../context/auth-context/AuthContext';
import { jwtDecode } from 'jwt-decode';

interface Props {
    children?: React.ReactNode;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
    
    const { user, setUser, isTokenExpired } = useAuth();

    React.useEffect(() => {
        if (!user && !isTokenExpired()) {
            const rememberMe = !!localStorage.getItem('token');
            const token = rememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token');
            if (token) {
                const decodedToken = jwtDecode<MyNewJwtPayload>(token);
                if (Date.now() / 1000 < decodedToken.exp) {
                    setUser({
                        name: decodedToken.name,
                        email: decodedToken.preferred_username,
                        roles: decodedToken.roles,
                        nbf: decodedToken.nbf,
                        expired: decodedToken.exp,
                        iat: decodedToken.iat,
                        iss: decodedToken.iss,
                        token: token,
                    });
                } else {
                    setUser(null)
                    localStorage.removeItem('token');
                    sessionStorage.removeItem('token');
                }
            }
        } else if (isTokenExpired()) {
            setUser(null)
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
        }
    }, [user, setUser, isTokenExpired]);

    if (isTokenExpired()) {
        setUser(null)
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        return <Navigate to="/login" replace />;
    }

    return children ? <>{children}</> : <Outlet />;
}

export default ProtectedRoute;