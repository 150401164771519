// src/context/EventViewContext.tsx
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { showSnackbar } from '../../utils/Utils';
import { useAuth } from '../auth-context/AuthContext';
import { Status } from '../../components/layout/event-content/EventData';
import { useFetchWithAuth } from '../../hooks/useFetchWithAuth';

interface EventViewContextType {
    eventFilter: string;
    setEventFilter: (val: string) => void;
    loading: boolean;
    error: string | null;
    events: any;
    setEvents: (val: any) => void;
    page: number;
    setPage: (val: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (val: number) => void;
    createEvent: (val: any) => void;
    saveEvent: (id: number, body: any) => void;
    status: Status[];
    eventType: Status[];
    assignedToList: string[];
    fetchEvents: () => void;
    fetchStatus: () => void;
    fetchType: () => void;
    fetchAssignedToList: () => void;
}

const EventContext = createContext<EventViewContextType | undefined>(undefined);

export const EventViewProvider = ({ children }: { children: ReactNode }) => {

    const { setSnackbarOpen, setSnackbarMessage, setVariant } = useAuth();
    const fetchWithAuth = useFetchWithAuth()
    const [eventFilter, setEventFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [events, setEvents] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<Status[]>([])
    const [eventType, setEventType] = useState<Status[]>([])
    const [assignedToList, setAssignedToList] = useState<string[]>([])

    const fetchEvents = async () => {
        try {
            setLoading(true);
            const response = await fetchWithAuth('/api/Event', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch event data');
            }
            const data = await response.json();
            setEvents(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const fetchStatus = async () => {
        try {
            setLoading(true);
            const response = await fetchWithAuth('/api/Event/Statuses', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch event status data');
            }
            const data = await response.json();
            setStatus(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const fetchType = async () => {
        try {
            setLoading(true);
            const response = await fetchWithAuth('/api/Event/EventTypes', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch event type data');
            }
            const data = await response.json();
            setEventType(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const fetchAssignedToList = async () => {
        try {
            setLoading(true);
            const response = await fetchWithAuth('/api/Event/AssignedTo', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch event type data');
            }
            const data = await response.json();
            setAssignedToList(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const createEvent = async (eventData: any) => {
        try {
            setLoading(true);
            const response = await fetchWithAuth('/api/Event', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData)
            });
            if (!response.ok) {
                throw new Error('Failed to add new event');
            }
            const data = await response.json();
            const eventId = Number(data.id);
            const newEvent = { ...eventData, id: eventId };

            setEvents((prevEvents: any) => [...prevEvents, newEvent]);
            setVariant('success');
            showSnackbar('New event successfully added.', setSnackbarOpen, setSnackbarMessage);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
            setVariant('error');
            showSnackbar(`${err}`, setSnackbarOpen, setSnackbarMessage);
        } finally {
            setLoading(false);
        }
    };

    const saveEvent = async (eventId: number, eventData: any): Promise<void> => {
        try {
            setLoading(true);
            const response = await fetchWithAuth(`/api/Event/${eventId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData)
            });
            if (!response.ok) {
                throw new Error('Failed to save event data');
            }

            setEvents((prevEvents: any[]) => {
                const updatedEvents = prevEvents.map(event => {
                    if (event.id === eventId) {
                        return { ...event, ...eventData };
                    }
                    return event;
                });
                return updatedEvents;
            });

            setVariant('success');
            showSnackbar(`Successfully updated event.`, setSnackbarOpen, setSnackbarMessage);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
            setVariant('error');
            showSnackbar(`${err}`, setSnackbarOpen, setSnackbarMessage);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return (
        <EventContext.Provider
            value={{
                eventFilter,
                setEventFilter,
                loading,
                error,
                events,
                setEvents,
                page,
                setPage,
                rowsPerPage,
                setRowsPerPage,
                createEvent,
                saveEvent,
                status,
                eventType,
                assignedToList,
                fetchEvents,
                fetchStatus,
                fetchType,
                fetchAssignedToList
            }}>
            {children}
        </EventContext.Provider>
    );
};

export const useEventView = () => {
    const context = useContext(EventContext);
    if (context === undefined) {
        throw new Error('useEventView must be used within an EventViewProvider');
    }
    return context;
};
