import React from 'react'
import { Box, Snackbar, Alert, useMediaQuery, Backdrop, CircularProgress } from '@mui/material'
import fishFarm from '../../asset/Images/fish-farm.png'
// import EventTable from '../../components/layout/event-content/EventTable'
import { useAuth } from '../../context/auth-context/AuthContext'
import { useEventView } from '../../context/event-context/EventContext'
import EventContent from '../../components/layout/event-content/EventContent'

const EventPage = () => {

  const { snackbarOpen, setSnackbarOpen, snackbarMessage, variant } = useAuth()
  const { loading } = useEventView()
  const alertSeverity: 'success' | 'info' | 'warning' | 'error' = (['success', 'info', 'warning', 'error'].includes(variant) ? variant : 'info') as 'success' | 'info' | 'warning' | 'error';
  const isTabletOrDesktop = useMediaQuery('(min-width: 1150px)');

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100vh',
        backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%), url(${fishFarm})`,
        backgroundPosition: 'top left',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: 0,
        top: 0,
        left: 0,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: isTabletOrDesktop ? '120px 50px 50px 50px' : '90px 20px 20px 20px',
      }}
    >
      {/* <EventTable /> */}
      <EventContent />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {
        loading && (
          <Backdrop
            open={loading}
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )
      }
    </Box>
  )
}

export default EventPage