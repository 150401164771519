// src/hooks/useLogin.ts
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { LoginFormValues } from '../pages/login-page/LoginLib';
import { useAuth } from '../context/auth-context/AuthContext';

const useLogin = () => {

    const navigate = useNavigate();
    const { login } = useAuth();

    const onSubmit = async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
        try {
            await login(values.username, values.password, values.rememberMe);
            navigate('/map-view');
        } catch (error) {
            console.log(error)
        } finally {
            actions.setSubmitting(false);
        }
    };

    return { onSubmit };

};

export default useLogin;
