import { useCallback } from 'react';
import { useAuth } from '../context/auth-context/AuthContext';

// check the token is expired
export const useCheckAndRefreshToken = () => {
  const { isTokenExpired, refreshToken, user } = useAuth();

  const checkAndRefreshToken = useCallback(async (): Promise<string | null> => {
    if (isTokenExpired()) {
      await refreshToken();
    }
    return user?.token || null;
  }, [isTokenExpired, refreshToken, user]);

  return checkAndRefreshToken;
};