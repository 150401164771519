import { SxProps, Theme } from '@mui/material/styles';

export const buttonStyle = (color: string, isSmallScreen: boolean): SxProps<Theme> => ({
    color: '#fff',
    borderColor: color,
    backgroundColor: color,
    borderRadius: 2,
    textTransform: 'capitalize',
    fontSize: isSmallScreen ? '11px' : '14px',
    ':hover': {
        color: '#fff',
        borderColor: color,
        backgroundColor: color,
        opacity: 0.8,
    },
});

export const disabledButtonStyle = (color: string, isSmallScreen: boolean): SxProps<Theme> => ({
    color,
    borderColor: color,
    borderRadius: 2,
    textTransform: 'capitalize',
    fontSize: isSmallScreen ? '11px' : '14px',
    ':hover': {
        color: '#fff',
        borderColor: color,
        backgroundColor: color,
        opacity: 0.8,
    },
});

export const containerDocumentActionStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 1,
    position: 'relative',
};

// document content
export const containerDocumentContentStyle: SxProps<Theme> = {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    zIndex: 2,
    backgroundColor: 'white',
    padding: 2,
};

export const headerStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    gap: 2
};

export const listViewStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 2,
};

export const logoStyle: React.CSSProperties = {
    position: 'absolute',
    right: -15,
    bottom: -15,
    width: 200,
    opacity: 0.2,
    zIndex: 4,
};

// document navigation
export const formControl: React.CSSProperties = {
    minWidth: 120,
    marginBottom: 2,
    textAlign: 'left',
}

export const breadcrumbText: SxProps<Theme> = {
    textTransform: 'capitalize',
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
        color: '#f58e3f',
    },
}

export const formContainer: React.CSSProperties = {
    textAlign: 'left' as const,
}
