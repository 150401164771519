import React, { useRef, useEffect, useState, createContext, useContext, useCallback } from 'react';
import { useAuth } from '../auth-context/AuthContext';
import { useFetchWithAuth } from '../../hooks/useFetchWithAuth';
import { MapRef } from 'react-map-gl';
import { Viewport } from '../../pages/map-view-page/MapViewLib';

interface MapViewContextType {
  mapRef: React.MutableRefObject<MapRef | null>;
  initialViewport: Viewport;
  setInitialViewport: (val: Viewport) => void;
  viewport: Viewport;
  setViewport: (val: Viewport) => void;
  mapStyle: string;
  setMapStyle: (val: string) => void;
  mapboxAccessToken: string;
  showMenu: boolean;
  setShowMenu: (val: boolean) => void;
  layerFilter: string;
  setLayerFilter: (val: string) => void;
  loading: boolean;
  error: string | null;
  menu: any;
  popupInfo: any;
  setPopupInfo: (val: any) => void;
  fetchLayer: (layerId: string, subGroupId: string) => void;
  layersData: any;
  selectedLayer: string | null;
  setSelectedLayer: (val: string | null) => void;
  region: any;
  setRegion: (val: any) => void;
  stations: any;
  setStations: (val: any) => void;
  selectedStation: any;
  setSelectedStation: (val: any) => void;
  fishFarm: any;
  setFishFarm: (val: any) => void;
  dataType: string;
  setDataType: (val: string) => void;
  dataTypeLabel: string;
  setDataTypeLabel: (val: string) => void;
  visibility: any;
  setVisibility: (val: any) => void;
  isShowFishFarms: boolean;
  setIsShowFishFarms: (val: boolean) => void;
  isShowTimeSlider: boolean;
  setIsShowTimeSlider: (val: boolean) => void;
  fetchStationData: (val: string) => void;
  fetchStationById: (val: string) => void;
  highlightLayer: string[];
  setHighlightLayer: React.Dispatch<React.SetStateAction<string[]>>;
  availableDates: string[];
  setAvailableDates: (val: string[]) => void;
  fetchAvailableDates: (dataType: string) => void;
  selectedDate: string;
  setSelectedDate: (val: string) => void;
  fetchStationsByDate: (dataType: string, date: string) => void;
}

const MapContext = createContext<MapViewContextType | undefined>(undefined);

export const MapViewProvider = ({ children }: { children: React.ReactNode }) => {

  const { baseUrl, isLoggedIn, user, setSnackbarOpen, setSnackbarMessage, setVariant } = useAuth();
  const fetchWithAuth = useFetchWithAuth();
  const mapRef = useRef<MapRef | null>(null);
  const mapboxAccessToken = "pk.eyJ1IjoidHNhbGlzcm9zeWFkaSIsImEiOiJjbHF4YXM2cXMwOWswMmptaTZ5MnM4d2l1In0._672ljsVEiL51bp5b_PP7g";
  const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/light-v11?optimize=true");
  const [initialViewport, setInitialViewport] = useState<Viewport>({
    longitude: 103.83903186078327,
    latitude: 1.3160095008363117,
    zoom: 10.7,
    pitch: 0,
    bearing: 0,
  });
  const [viewport, setViewport] = useState<Viewport>(initialViewport);
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const [layerFilter, setLayerFilter] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [menu, setMenu] = useState<any>([]);
  const [layersData, setLayersData] = useState<{ [key: string]: any[] }>({});
  const [selectedLayer, setSelectedLayer] = useState<string | null>(null);
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [region, setRegion] = useState<any>(null);
  const [fishFarm, setFishFarm] = useState<any>(null);
  const [stations, setStations] = useState<any>([]);
  const [selectedStation, setSelectedStation] = useState<any>(null);
  const [dataType, setDataType] = useState<string>('WaterQuality');
  const [dataTypeLabel, setDataTypeLabel] = useState<string>('Online Water Quality Monitorng')
  const [visibility, setVisibility] = useState<any>({});
  const [isShowFishFarms, setIsShowFishFarms] = useState<boolean>(true);
  const [isShowTimeSlider, setIsShowTimeSlider] = useState<boolean>(false);
  const [highlightLayer, setHighlightLayer] = useState<string[]>([])
  const [availableDates, setAvailableDates] = useState<string[]>([])
  const [selectedDate, setSelectedDate] = useState('')

  const fetchMenu = async () => {
    try {
      setLoading(true);
      const response = await fetchWithAuth('/api/Map/menu', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch menu data');
      }
      const data = await response.json();

      setMenu(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (baseUrl && user?.token) {
      fetchMenu();
    }
  }, [baseUrl, isLoggedIn]);

  const fetchLayer = async (layerId: string, subGroupId: string) => {
    try {
      setLoading(true);
      const response = await fetchWithAuth(`/api/Map/maplayer?layerId=${layerId.split(' ').join('')}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch layer data');
      }
      const data = await response.json();
      if (layerId === 'FishFarms') {
        setFishFarm(data);
      } else {
        setLayersData(prev => ({
          ...prev,
          [subGroupId]: data.features,
        }));
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  const fetchStationData = useCallback(async (dataType: string) => {
    try {
      setLoading(true);
      const response = await fetchWithAuth(`/api/Stations?dataType=${dataType}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch station data for ${dataType}`);
      }

      const data = await response.json();
      const formattedData = data.map((item: any) => ({ ...item, dataType }));

      setStations((prevStations: any[]) => {
        const updatedStations = prevStations.filter((station) => station.dataType !== dataType);
        return [...updatedStations, ...formattedData];
      });
    } catch (err) {
      console.error(`Error fetching ${dataType}:`, err);
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  }, [baseUrl, fetchWithAuth, user?.token]);

  useEffect(() => {
    if (baseUrl && user?.token) {
      fetchStationData('WaterQuality')
      fetchLayer('FishFarms', 'fishFarmsSubGroup');
    }
  }, [baseUrl, user?.token]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchStationData('WaterQuality');
    }, 300000);

    return () => clearInterval(interval);
  }, [fetchStationData]);

  const fetchStationById = async (stationId: string) => {
    try {
      setLoading(true);
      const response = await fetchWithAuth(`/api/Stations/${stationId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch station data by Id: ${stationId}`);
      }

      const data = await response.json();
      setSelectedStation(data);
      setPopupInfo(data);

    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
      setError(errorMessage);

      setSnackbarMessage(`Error fetching station data: ${errorMessage}`);
      setSnackbarOpen(true);
      setVariant('error');

    } finally {
      setLoading(false);
    }
  };

  const fetchAvailableDates = async (dataType: string) => {
    try {
      setLoading(true);
  
      const currentDate = new Date().toISOString().split('T')[0];
      const startDate = '2024-01-01';
      const endpoint = `${baseUrl}/api/Stations/DataAvailableDates?dataType=${dataType}&start=${startDate}&end=${currentDate}`;
  
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user?.token}`
        },
        body: JSON.stringify({}),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch available dates for data type: ${dataType}`);
      }
  
      const data = await response.json();
      setAvailableDates(data);
  
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
      setError(errorMessage);
      setSnackbarMessage(`Error fetching available dates for data type: ${errorMessage}`);
      setSnackbarOpen(true);
      setVariant('error');
    } finally {
      setLoading(false);
    }
  };

  const fetchStationsByDate = async (dataType: string, date: string) => {
    try {
      
      const endpoint = `${baseUrl}/api/Stations/GetByDate?dataType=${dataType}&date=${date}`;

      const response = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user?.token}`
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch available dates for data type: ${dataType}`);
      }

      const data = await response.json();
    
      const formattedData = data.map((item: any) => ({ ...item, dataType }));

      setStations((prevStations: any[]) => {
        const updatedStations = prevStations.filter((station) => station.dataType !== dataType);
        return [...updatedStations, ...formattedData];
      });

    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
      setError(errorMessage);
      setSnackbarMessage(`Error fetching available dates for data type: ${errorMessage}`);
      setSnackbarOpen(true);
      setVariant('error');

    }
  };

  return (
    <MapContext.Provider
      value={{
        mapRef,
        initialViewport,
        setInitialViewport,
        viewport,
        setViewport,
        mapStyle,
        setMapStyle,
        mapboxAccessToken,
        showMenu,
        setShowMenu,
        layerFilter,
        setLayerFilter,
        loading,
        error,
        menu,
        popupInfo,
        setPopupInfo,
        fetchLayer,
        layersData,
        selectedLayer,
        setSelectedLayer,
        region,
        setRegion,
        stations,
        setStations,
        selectedStation,
        setSelectedStation,
        fishFarm,
        setFishFarm,
        dataType,
        setDataType,
        dataTypeLabel,
        setDataTypeLabel,
        visibility,
        setVisibility,
        isShowFishFarms,
        setIsShowFishFarms,
        isShowTimeSlider,
        setIsShowTimeSlider,
        fetchStationData,
        fetchStationById,
        highlightLayer,
        setHighlightLayer,
        availableDates,
        setAvailableDates,
        fetchAvailableDates,
        selectedDate,
        setSelectedDate,
        fetchStationsByDate
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapView = () => {
  const context = useContext(MapContext);
  if (context === undefined) {
    throw new Error('useMapView must be used within a MapViewProvider');
  }
  return context;
};
