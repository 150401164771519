export const displayDocuments = [
    {
        name: 'March',
        size: '1.42 mb',
        modified: '2024-03-08 05:43:41',
        type: 'folder',
      },
      {
        name: 'test.txt',
        size: '0 kb',
        modified: '2024-03-08 05:43:30',
        type: 'file',
      },
]