import React, { useState, useEffect } from 'react';
import {
    Box, Table, TableContainer, TablePagination, Paper, useMediaQuery, IconButton, TableCell,
    TableHead, TableRow, Tooltip, Typography, TableBody, Popover, TextField, Button, Select, MenuItem
} from '@mui/material';
import ButtonWithIcon from '../../common/button/Button';
import AddIcon from '@mui/icons-material/Add';
import { handleChangePage, handleChangeRowsPerPage } from '../../../utils/EventUtils';
import CreateEvent from './CreateEvent';
import { buttonStyle, containerStyle } from './Style';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { useEventView } from '../../../context/event-context/EventContext';
import { ArrowDropDown, ArrowDropUp, Cancel, Edit, FilterList, Save } from '@mui/icons-material';
import { formatDate } from '../../../utils/Utils';
import { eventLabel } from './EventData';
import TableFilter from '../../feature/filter/TableFilter';

export type SortConfig = {
    key: string | null;
    direction: 'ascending' | 'descending' | null;
};

export type DataRow = {
    id: number;
    type: number;
    description: string;
    startedDateTime: string;
    reportedDateTime: string;
    completedDateTime: string;
    createdBy: string;
    status: number;
    assignedTo: string;
    remarks: string;
};

const EventContent: React.FC = () => {
    const {
        events,
        setEvents,
        status,
        eventType,
        assignedToList,
        eventFilter,
        saveEvent,
        fetchEvents,
        fetchAssignedToList,
        fetchStatus,
        fetchType
    } = useEventView();
    const { baseUrl, user } = useAuth();

    const isSmallScreen = useMediaQuery('(min-width: 999px)');
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [orderBy, setOrderBy] = useState<string>('stationId');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterLabel, setFilterLabel] = useState<string | null>(null)
    const [filterField, setFilterField] = useState<string | number | Date | null>(null);
    const [filterValue, setFilterValue] = useState<string | number | Date>('');
    const [filters, setFilters] = useState<{ [key: string]: string }>({});

    const [editingRow, setEditingRow] = useState<number | null>(null);
    const [editableValues, setEditableValues] = useState<{ [key: number]: Partial<DataRow> }>({});

    useEffect(() => {
        const fetchData = async () => {
            if (events.length === 0) {
                await fetchEvents();
            }
            if (assignedToList.length === 0) {
                await fetchAssignedToList();
            }
            if (status.length === 0) {
                await fetchStatus();
            }
            if (eventType.length === 0) {
                await fetchType();
            }
        };

        if (baseUrl && user?.token) {
            fetchData();
        }
    }, [baseUrl, user?.token]);

    const rowStyle = { minWidth: 180, fontSize: isSmallScreen ? 12 : 11 };

    const handleSort = (key: string) => {
        let direction: 'ascending' | 'descending' = 'ascending';

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        setSortConfig({ key, direction });
        setOrderBy(key);

        const sortedEvents = [...events].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        setEvents(sortedEvents);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const handleFilterClick = (event: React.MouseEvent<HTMLElement>, field: any) => {
        setAnchorEl(event.currentTarget);
        setFilterLabel(field.label)
        setFilterField(field.value);
        setFilterValue(''); // Reset filter value when selecting a new filter field
    };

    const handleFilterApply = () => {
        if (filterField) {
            setFilters(prev => ({ ...prev, [filterField as any]: filterValue }));
        }
        handleFilterClose();
    };

    const filteredEvents = events.filter((event: { [x: string]: any }) => {
        return Object.keys(filters).every(key => {
            const fieldValue = event[key];
            const filterValue = filters[key].toLowerCase();
            return fieldValue !== undefined && fieldValue !== null
                ? fieldValue.toString().toLowerCase().includes(filterValue)
                : false;
        });
    });

    const renderInputField = (field: string | number | Date) => {
        switch (field) {
            case 'type':
                return (
                    <Select
                        fullWidth
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value as string)}
                    >
                        <MenuItem value="">- Select All -</MenuItem>
                        <MenuItem value="0">Sensors</MenuItem>
                        <MenuItem value="1">Samples</MenuItem>
                        <MenuItem value="2">Others</MenuItem>
                    </Select>
                );
            case 'status':
                return (
                    <Select
                        fullWidth
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value as string)}
                    >
                        <MenuItem value="">- Select All -</MenuItem>
                        <MenuItem value="0">New</MenuItem>
                        <MenuItem value="1">In Progress</MenuItem>
                        <MenuItem value="2">Completed</MenuItem>
                        <MenuItem value="3">On Hold</MenuItem>
                    </Select>
                );
            default:
                return (
                    <TextField
                        label={`Enter ${filterLabel}`}
                        variant="outlined"
                        fullWidth
                        type={['startedDateTime', 'completedDateTime', 'reportedDateTime'].includes(filterField as any) ? 'date' : 'text'}
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value as string | Date | number)}
                        sx={{ marginTop: 1, fontSize: 11 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                );
        }
    };

    const handleClickEdit = (id: number) => {
        setEditingRow(id);
        setEditableValues(prev => ({
            ...prev,
            [id]: { ...events.find((event: { id: number; }) => event.id === id) }
        }));
    };

    const handleClickCancel = () => {
        setEditingRow(null);
    }

    const handleEditableChange = (id: number, field: keyof DataRow, value: string | number) => {
        setEditableValues(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [field]: value
            }
        }));
    };

    const handleSave = (id: number) => {
        const originalEvent = events.find((event: { id: number; }) => event.id === id);
        const updatedEvent = { ...originalEvent, ...editableValues[id] };

        saveEvent(id, updatedEvent);
        setEditingRow(null);
        setEditableValues({});
    };

    const sortedData = filteredEvents.filter((item: any) => (
        (item.id?.toString() ?? '').includes(eventFilter) ||
        (item.description?.toLowerCase() ?? '').includes(eventFilter) ||
        (item.createdBy?.toLowerCase() ?? '').includes(eventFilter) ||
        (item.assignedTo?.toLowerCase() ?? '').includes(eventFilter) ||
        (item.remarks?.toLowerCase() ?? '').includes(eventFilter)
    ));

    const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={containerStyle}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" padding={1} marginTop={1} marginBottom={1}>
                <Box position="relative" sx={{ width: '100%', textAlign: 'left' }}>
                    <ButtonWithIcon
                        variant='outlined'
                        icon={<AddIcon />}
                        onClick={() => setShowCreateForm(true)}
                        text='Create New Event'
                        sx={buttonStyle(isSmallScreen)}
                    />
                    {showCreateForm && <CreateEvent onClose={() => setShowCreateForm(false)} />}
                </Box>
                <TableFilter />
            </Box>
            <Box sx={{ padding: 1 }}>
                <TableContainer component={Paper} sx={{ maxHeight: '65vh' }}>
                    <Table stickyHeader aria-label="sticky table" size='small'>
                        <TableHead>
                            <TableRow>
                                {
                                    eventLabel.map(item => (
                                        <TableCell
                                            key={item.label}
                                            align="left"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => setOrderBy(item.value)}
                                        >
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                fontSize: isSmallScreen ? 14 : 12,
                                                display: 'inline-block'
                                            }}>
                                                {item.label}
                                            </Typography>
                                            {(orderBy === item.value) ? (
                                                sortConfig.direction === 'ascending' ? (
                                                    <Tooltip title={`Sort ${sortConfig.direction}`}>
                                                        <IconButton
                                                            size="small"
                                                            aria-label="sorting data point"
                                                            aria-controls="sorting data point"
                                                            aria-haspopup="true"
                                                            color="inherit"
                                                            onClick={() => handleSort(item.value)}
                                                        >
                                                            <ArrowDropDown sx={{ fontSize: 16, display: 'inline-block' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title={`Sort ${sortConfig.direction}`}>
                                                        <IconButton
                                                            size="small"
                                                            aria-label="sorting data point"
                                                            aria-controls="sorting data point"
                                                            aria-haspopup="true"
                                                            color="inherit"
                                                            onClick={() => handleSort(item.value)}
                                                        >
                                                            <ArrowDropUp sx={{ fontSize: 16, display: 'inline-block' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            ) : null}
                                            {(orderBy === item.value) ? (
                                                <Tooltip title={`Filter by ${item.label}`}>
                                                    <IconButton
                                                        size="small"
                                                        aria-label="filter data point"
                                                        aria-controls="filter data point"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                        onClick={(e) => handleFilterClick(e, item)}
                                                    >
                                                        <FilterList sx={{ fontSize: 16, display: 'inline-block' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : null}
                                        </TableCell>
                                    ))
                                }
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.length > 0 ? (
                                paginatedData.map((data: any, index: number) => {
                                    const typeLabelConfig = eventLabel.find(event => event.value === 'type');
                                    const statusLabelConfig = eventLabel.find(event => event.value === 'status');

                                    const formattedType = typeLabelConfig?.formatter ? typeLabelConfig.formatter(data.type) : data.type;
                                    const formattedStatus = statusLabelConfig?.formatter ? statusLabelConfig.formatter(data.status) : data.status;

                                    const isEditing = editingRow === data.id;

                                    return (
                                        <TableRow key={`${data.id}-${index}`}>
                                            <TableCell component="th" scope="row" sx={rowStyle}>{data.id}</TableCell>
                                            <TableCell align="left" sx={rowStyle}>{formattedType}</TableCell>
                                            <TableCell align="left" sx={rowStyle}>{data.description}</TableCell>
                                            <TableCell align="left" sx={rowStyle}>
                                                {data.startedDateTime ? formatDate(data.startedDateTime, true) : ''}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle}>
                                                {data.completedDateTime ? formatDate(data.completedDateTime, true) : ''}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle}>{data.createdBy}</TableCell>
                                            <TableCell align="left" sx={rowStyle}>
                                                {data.reportedDateTime ? formatDate(data.reportedDateTime, false) : ''}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle}>
                                                {isEditing ? (
                                                    <Select
                                                        fullWidth
                                                        value={editableValues[data.id]?.status || data.status}
                                                        sx={{ height: 30, fontSize: 12 }}
                                                        onChange={(e) => handleEditableChange(data.id, 'status', parseInt(e.target.value as string))}
                                                    >
                                                        <MenuItem value="0">New</MenuItem>
                                                        <MenuItem value="1">In Progress</MenuItem>
                                                        <MenuItem value="2">Completed</MenuItem>
                                                        <MenuItem value="3">On Hold</MenuItem>
                                                    </Select>
                                                ) : (
                                                    formattedStatus
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle}>
                                                {isEditing ? (
                                                    <Select
                                                        fullWidth
                                                        value={editableValues[data.id]?.assignedTo}
                                                        sx={{ height: 30, fontSize: 12 }}
                                                        onChange={(e) => handleEditableChange(data.id, 'assignedTo', e.target.value as string)}
                                                    >
                                                        {assignedToList.map((label, index) => (
                                                            <MenuItem
                                                                value={label}
                                                                key={index}
                                                            >
                                                                {label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                ) : (
                                                    data.assignedTo
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle}>
                                                {isEditing ? (
                                                    <TextField
                                                        fullWidth
                                                        value={editableValues[data.id]?.remarks}
                                                        onChange={(e) => handleEditableChange(data.id, 'remarks', e.target.value)}
                                                        sx={{
                                                            fontSize: 12,
                                                            '& .MuiInputBase-root': {
                                                                height: 30,
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontSize: 12,
                                                                padding: '6px 8px',
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    data.remarks
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                {isEditing ? (
                                                    <>
                                                        <IconButton onClick={() => handleSave(data.id)}>
                                                            <Save />
                                                        </IconButton>
                                                        <IconButton onClick={handleClickCancel}>
                                                            <Cancel />
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    <IconButton onClick={() => handleClickEdit(data.id)}>
                                                        <Edit />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={eventLabel.length + 1} align="center">
                                        <Typography>No data available</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={filteredEvents.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage(setPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage(setRowsPerPage, setPage)}
                />
            </Box>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={1}>
                    {renderInputField(filterField!)}
                    <Box display="flex" justifyContent="flex-end" mt={1}>
                        <Button variant="contained" onClick={handleFilterApply}>Apply</Button>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
};

export default EventContent;
