import React, { createContext, useContext, ReactNode, useState } from 'react';
import { displayDocuments } from '../../pages/document-page/DocumentViewLib';
import { useAuth } from '../auth-context/AuthContext';
import { createDownloadLink, sanitizeFilename } from '../../utils/Utils';
import { useFetchWithAuth } from '../../hooks/useFetchWithAuth';

interface DocumentViewContextType {
    loading: boolean;
    error: string | null;
    documentList: any;
    path: any;
    setPath: (val: any) => void;
    root: string;
    setRoot: (val: string) => void;
    downLoadDocument: (val: string) => void;
    deleteDocument: (fileId: string) => void;
    goBack: () => void;
    goUp: () => void;
    canGoBack: boolean;
    canGoUp: boolean;
    fetchDocumentList: () => void;
    uploadDocument: (file: any, newPath: string, fileName: string, tags: string) => void;
    renameDocument: (fileId: any, newFileName: string) => void;
}

const DocumentContext = createContext<DocumentViewContextType | undefined>(undefined);

export const DocumentViewProvider = ({ children }: { children: ReactNode }) => {
    
    const { setSnackbarOpen, setSnackbarMessage, setVariant, user } = useAuth();
    const fetchWithAuth = useFetchWithAuth()
    const [loading, setLoading] = useState<boolean>(true);
    const [documentList, setDocumentList] = useState<any>(displayDocuments);
    const [error, setError] = useState<string | null>(null);
    const [root, setRoot] = useState<string>('Documents');
    const [path, setPath] = useState<any>(['Documents']);
    const [pathHistory, setPathHistory] = useState<any>([]);
    const canGoBack = pathHistory.length > 0;
    const canGoUp = path.length > 1;

    const fetchDocumentList = async () => {
        try {
            setLoading(true);
            const basePath = '/api/Document/Tree';
            const pathString = path.length === 1 && path[0] === 'Documents' ? '' : `?path=${path.join('%2F')}`;
            const response = await fetchWithAuth(`${basePath}${pathString}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch documents data');
            }
            const data = await response.json();
            setDocumentList(data);
            const newPath = data.directories?.[0]?.path.map((p: { name: any; }) => p.name) || ['Documents'];
            setPath(newPath);

        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const downLoadDocument = async (fileId: string) => {
        try {
            const response = await fetchWithAuth(`/api/Document/Download?id=${fileId}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to download document');
            }
            const disposition = response.headers.get('Content-Disposition');
            let filename = 'downloaded_file';
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            filename = sanitizeFilename(filename);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            createDownloadLink(downloadUrl, filename);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed:', error);
            setSnackbarMessage('Failed to download document. Please try again.');
            setSnackbarOpen(true);
            setVariant('error');
        }
    };

    const deleteDocument = async (fileId: string) => {
        try {
            const response = await fetchWithAuth(`/api/Document?id=${fileId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to delete document');
            }
            setSnackbarMessage('Document successfully deleted');
            setSnackbarOpen(true);
            setVariant('success');
            fetchDocumentList();
        } catch (error) {
            console.error('Delete failed:', error);
            setSnackbarMessage('Failed to delete document. Please try again.');
            setSnackbarOpen(true);
            setVariant('error');
        }
    };

    const setNewPath = (newPath: any) => {
        setPathHistory((prev: any) => [...prev, path]);
        setPath(newPath);
    };

    const goBack = () => {
        if (pathHistory.length > 0) {
            const previousPath = pathHistory[pathHistory.length - 1];
            setPath(previousPath);
            setPathHistory((prev: string | any[]) => prev.slice(0, -1));
        }
    };

    const goUp = () => {
        if (path.length > 1) {
            const newPath = path.slice(0, -1);
            setNewPath(newPath);
        }
    };

    const uploadDocument = async (file: any, newPath: string, fileName: string, tags: string) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('FileName', fileName);
        const relativePath = newPath.startsWith(path.join('/')) ? newPath : `${path.join('/')}/${newPath}`;
        formData.append('RelativePath', relativePath);
        formData.append('UploadedUser', user.initial);
        formData.append('Tags', tags);
        try {
            const response = await fetchWithAuth('/api/Document/Upload', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to upload document');
            }
            setSnackbarMessage('Document successfully uploaded');
            setSnackbarOpen(true);
            setVariant('success');
            fetchDocumentList();
        } catch (error) {
            console.error('Upload failed:', error);
            setSnackbarMessage('Failed to upload document. Please try again.');
            setSnackbarOpen(true);
            setVariant('error');
        }
    };

    const renameDocument = async (fileId: any, newFileName: string) => {
        const formData = new FormData();
        formData.append('FileId', fileId);
        formData.append('NewFileName', newFileName);
        formData.append('RelativePath', path.join('/'));
        formData.append('UpdatedUser', user.initial);
        try {
            const response = await fetchWithAuth(`/api/Document/Rename`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to rename document');
            }
            setSnackbarMessage('Document successfully renamed');
            setSnackbarOpen(true);
            setVariant('success');
            fetchDocumentList();
        } catch (error) {
            setSnackbarMessage(`${error}`);
            setSnackbarOpen(true);
            setVariant('error');
        }
    };

    return (
        <DocumentContext.Provider
            value={{
                loading,
                error,
                documentList,
                path,
                setPath,
                root,
                setRoot,
                downLoadDocument,
                deleteDocument,
                goBack,
                goUp,
                canGoBack,
                canGoUp,
                fetchDocumentList,
                uploadDocument,
                renameDocument
            }}>
            {children}
        </DocumentContext.Provider>
    );
};

export const useDocumentView = () => {
    const context = useContext(DocumentContext);
    if (context === undefined) {
        throw new Error('useDocumentView must be used within a DocumentViewProvider');
    }
    return context;
};
