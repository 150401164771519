import React from 'react';
import {
    ArrowRightOutlined, ArrowDropDownOutlined, FolderOutlined, MyLocation, Visibility, VisibilityOff,
    Update, UpdateDisabled
} from '@mui/icons-material';
import {
    Collapse, ListItem, ListItemText, List, Skeleton, Tooltip,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { useMapView } from '../../../context/map-context/mapContext';
import {
    handleGroupClick, handleSubGroupClick, handleClickLayer, handleVisibility, handleSubGroupVisibility,
    handleSubGroupZoom, handleFishFarmsGroup, isSubGroupVisible, fetchFishFarmLayer, getSubgroups, isGroupVisible,
    handleGroupVisibility, handleHighlightLayer,
    handleClickTimeslider
} from '../../../utils/MapUtils';
import { useTimeseriesView } from '../../../context/timeseries-context/timeseriesContext';
import { createStyles } from './Style';

const LayerCollapse: React.FC = () => {
    const theme = useTheme()
    const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))

    const styles = createStyles(theme, isXSmallScreen, isSmallScreen, isMediumScreen, isLargeScreen)
    const {
        mapRef, menu, dataType, setDataType, fetchLayer, layersData, layerFilter, setSelectedLayer, setPopupInfo, setSelectedStation,
        visibility, setVisibility, fishFarm, isShowFishFarms, setIsShowFishFarms, fetchStationData, fetchStationById,
        highlightLayer, setHighlightLayer, isShowTimeSlider, setIsShowTimeSlider, setDataTypeLabel, fetchAvailableDates
    } = useMapView();
    const { stationInfo, setStationInfo } = useTimeseriesView();

    const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});
    const [expandedSubGroups, setExpandedSubGroups] = React.useState<{ [key: string]: boolean }>({});
    const [loading, setLoading] = React.useState<boolean>(false);
    const fetchedLayers = React.useRef<{ [key: string]: boolean }>({});
    const fetchedStations = React.useRef<{ [key: string]: boolean }>({});

    React.useEffect(() => {
        if (layerFilter) {
            setLoading(true);
            setTimeout(() => setLoading(false), 500);
        }
    }, [layerFilter]);

    React.useEffect(() => {
        if (menu?.groups) {
            const initialVisibility: { [key: string]: boolean } = {};
            const fetched = fetchedLayers.current;

            menu.groups.forEach((group: any) => {
                if (group.dataType === 'WaterQuality') {
                    group.subGroups.forEach((subGroup: any, index: number) => {
                        const subGroupId = `subGroup-${group.id}-${index}`;
                        if (!initialVisibility[subGroup.layerId]) {
                            initialVisibility[subGroup.layerId] = true;
                        }
                        if (!fetched[subGroupId]) {
                            fetched[subGroupId] = true;
                            fetchLayer(subGroup.layerId, subGroupId);
                        }
                    });
                }
            });

            setVisibility((prev: any) => ({ ...prev, ...initialVisibility }));
        }
    }, [menu?.groups, fetchLayer, setVisibility]);

    return (
        <List sx={styles.list}>
            {menu?.groups?.filter((item: any) => item.name !== 'Fish Farms').map((group: any) => (
                <React.Fragment key={group.id}>
                    <ListItem
                        button
                        onClick={() =>
                            handleGroupClick(
                                group,
                                `group-${group.id}`,
                                setOpen,
                                setDataType,
                                setDataTypeLabel,
                                fetchedLayers,
                                fetchedStations,
                                fetchLayer,
                                setIsShowFishFarms,
                                isShowFishFarms,
                                fetchStationData
                            )}
                        sx={styles.listItemGroup}
                    >
                        {open[`group-${group.id}`] ? <ArrowDropDownOutlined /> : <ArrowRightOutlined />}
                        <FolderOutlined sx={{ mr: 1 }} />
                        <ListItemText primary={group.name} />
                        {/* Temporary Hide */}
                        {/* {group.dataType !== 'WaterQuality' && group.dataType === dataType && !isGroupVisible(group, layersData, visibility) && (
                            group.dataType === dataType && isShowTimeSlider ? (
                                <Update
                                    onClick={(e) => {
                                        handleClickTimeslider(e, group, dataType, isShowTimeSlider, setIsShowTimeSlider, fetchAvailableDates);
                                        fetchStationData(group.dataType)
                                    }}
                                    sx={{ color: '#F65E27' }}
                                />
                            ) : (
                                <UpdateDisabled
                                    onClick={(e) => handleClickTimeslider(e, group, dataType, isShowTimeSlider, setIsShowTimeSlider, fetchAvailableDates)}
                                    sx={{ color: '#C4C4C4' }}
                                />
                            )
                        )} */}
                        {isGroupVisible(group, layersData, visibility) ? (
                            <VisibilityOff
                                onClick={() => {
                                    handleGroupVisibility(
                                        group,
                                        layersData,
                                        setVisibility,
                                        visibility,
                                        setPopupInfo,
                                        isShowTimeSlider,
                                        setIsShowTimeSlider
                                    );
                                }}
                                sx={styles.iconHidden}
                            />
                        ) : (
                            <Visibility
                                onClick={() => {
                                    handleGroupVisibility(
                                        group,
                                        layersData,
                                        setVisibility,
                                        visibility,
                                        setPopupInfo,
                                        isShowTimeSlider,
                                        setIsShowTimeSlider
                                    );
                                }}
                                sx={styles.iconVisible}
                            />
                        )}
                    </ListItem>

                    <Collapse in={open[`group-${group.id}`]} timeout="auto" unmountOnExit>
                        {getSubgroups(group)?.map((subGroup: any, index: number) => (
                            <React.Fragment key={`subGroup-${group.id}-${index}`}>
                                <ListItem
                                    button
                                    sx={styles.listItemSubGroup}
                                    onClick={() => {
                                        handleSubGroupClick(
                                            subGroup.layerId,
                                            `subGroup-${group.id}-${index}`,
                                            setExpandedSubGroups,
                                            layersData,
                                            fetchedLayers,
                                            fetchLayer
                                        )
                                    }}
                                >
                                    <FolderOutlined sx={{ mr: 1, color: '#C4C4C4' }} />
                                    <ListItemText primary={subGroup.name} />
                                    <Tooltip title={`Zoom ${subGroup.name}`}>
                                        <MyLocation
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSubGroupZoom(`subGroup-${group.id}-${index}`, layersData, mapRef);
                                            }}
                                            sx={styles.iconDefault}
                                        />
                                    </Tooltip>
                                    {isSubGroupVisible(`subGroup-${group.id}-${index}`, layersData, visibility) ? (
                                        <Tooltip title={`Show ${subGroup.name}`}>
                                            <VisibilityOff
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleSubGroupVisibility(`subGroup-${group.id}-${index}`, layersData, setVisibility, setPopupInfo);
                                                }}
                                                sx={styles.iconHidden}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={`Hide ${subGroup.name}`}>
                                            <Visibility
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleSubGroupVisibility(`subGroup-${group.id}-${index}`, layersData, setVisibility, setPopupInfo);
                                                }}
                                                sx={styles.iconVisible}
                                            />
                                        </Tooltip>
                                    )}
                                </ListItem>

                                <Collapse in={expandedSubGroups[`subGroup-${group.id}-${index}`]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {loading ? (
                                            <React.Fragment>
                                                {Array.from({ length: 3 }).map((_, index) => (
                                                    <Skeleton key={index} variant="rectangular" width="100%" height={40} sx={{ mb: 1 }} />
                                                ))}
                                            </React.Fragment>
                                        ) : (
                                            layersData[`subGroup-${group.id}-${index}`]?.filter((item: any) => item.properties.Name.toLowerCase().includes(layerFilter.toLowerCase())).map((feature: any, idx: number) => (
                                                <ListItem
                                                    button
                                                    key={idx}
                                                    sx={styles.listItemFeature(highlightLayer, feature.properties.Name)}
                                                >
                                                    <ListItemText
                                                        primary={feature.properties.Name}
                                                        onClick={() => {
                                                            handleClickLayer(feature, setPopupInfo, setSelectedStation, setSelectedLayer, fetchStationById, stationInfo, setStationInfo, group.dataType);
                                                            handleHighlightLayer([feature.properties.Name], setHighlightLayer, false);
                                                        }}
                                                    />
                                                    {visibility[feature.properties.Name] ? <VisibilityOff sx={styles.iconHidden} onClick={() => handleVisibility(feature.properties.Name, setVisibility, setPopupInfo)} /> : <Visibility sx={styles.iconVisible} onClick={() => handleVisibility(feature.properties.Name, setVisibility, setPopupInfo)} />}
                                                </ListItem>
                                            ))
                                        )}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        ))}
                    </Collapse>
                </React.Fragment>
            ))}

            <React.Fragment key="fish-farms">
                <ListItem
                    button
                    onClick={() => {
                        handleGroupClick(
                            { dataType: 'FishFarms' },
                            'group-fish-farms',
                            setOpen,
                            setDataType,
                            setDataTypeLabel,
                            fetchedLayers,
                            fetchedStations,
                            fetchLayer,
                            setIsShowFishFarms,
                            isShowFishFarms,
                            fetchStationData
                        );
                    }}
                    sx={styles.listItemGroup}
                >
                    {open['group-fish-farms'] ? <ArrowDropDownOutlined /> : <ArrowRightOutlined />}
                    <FolderOutlined sx={{ mr: 1 }} />
                    <ListItemText primary="Fish Farms" />
                    {isShowFishFarms ? (
                        <VisibilityOff sx={styles.iconHidden} onClick={() => setIsShowFishFarms(!isShowFishFarms)} />
                    ) : (
                        <Visibility sx={styles.iconVisible} onClick={() => setIsShowFishFarms(!isShowFishFarms)} />
                    )}
                </ListItem>

                <Collapse in={open['group-fish-farms']} timeout="auto" unmountOnExit>
                    <React.Fragment key="subGroup-fish-farms">
                        <ListItem
                            button
                            sx={styles.listItemSubGroup}
                        >
                            <FolderOutlined sx={{ mr: 1 }} />
                            <ListItemText
                                primary="Fish Farms"
                                onClick={() => {
                                    handleSubGroupClick(
                                        'fish-farms-layer',
                                        'subGroup-fish-farms',
                                        setExpandedSubGroups,
                                        layersData,
                                        fetchedLayers,
                                        fetchLayer
                                    )
                                }}
                            />
                            <MyLocation sx={styles.iconDefault} onClick={() => handleFishFarmsGroup(mapRef, fishFarm)} />
                            {isShowFishFarms ? (
                                <VisibilityOff sx={styles.iconHidden} onClick={() => setIsShowFishFarms(!isShowFishFarms)} />
                            ) : (
                                <Visibility sx={styles.iconVisible} onClick={() => setIsShowFishFarms(!isShowFishFarms)} />
                            )}
                        </ListItem>

                        <Collapse in={expandedSubGroups['subGroup-fish-farms']} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem
                                    sx={styles.listItemFeature(highlightLayer, 'Fish Farms')}
                                >
                                    <ListItemText primary="Fish Farms" onClick={() => fetchFishFarmLayer(fetchLayer)} />
                                    {isShowFishFarms ? <VisibilityOff sx={styles.iconHidden} onClick={() => setIsShowFishFarms(!isShowFishFarms)} /> : <Visibility sx={styles.iconVisible} onClick={() => setIsShowFishFarms(!isShowFishFarms)} />}
                                </ListItem>
                            </List>
                        </Collapse>
                    </React.Fragment>
                </Collapse>
            </React.Fragment>
        </List>
    );
};

export default LayerCollapse;