export const containerStyle = {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    zIndex: 2,
    backgroundColor: 'white'
};

export const buttonStyle = (isSmallScreen: boolean) => ({
    color: '#fff',
    fontSize: isSmallScreen ? '14px' : '11px',
    borderColor: '#f58e3f',
    backgroundColor: '#f58e3f',
    borderRadius: 2,
    ':hover': {
        color: '#fff',
        borderColor: '#f58e3f',
        backgroundColor: '#f58e3f',
        opacity: 0.8,
    },
});