import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Microsoft, ArrowBackIos } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import Text from '../../common/text/Text';
import { TextField } from 'formik-mui';
import ButtonWithIcon from '../../common/button/Button';
import { InitialUser, MyNewJwtPayload, useAuth } from '../../../context/auth-context/AuthContext';
import useLogin from '../../../hooks/useLogin';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { jwtDecode } from 'jwt-decode';
import {
    boxStyle,
    headerBoxStyle,
    navLinkStyle,
    iconStyle,
    textStyle,
    formBoxStyle,
    fieldStyle,
    actionBoxStyle,
    buttonStyle,
    CustomCheckbox,
    microsoftButton
} from './Style';

const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
});

const LoginForm: React.FC = () => {

    const {
        initialUser,
        setInitialUser,
        setUser,
        loading,
        OidcLogin,
        setSnackbarMessage,
        setOpenNotification
    } = useAuth();
    const { onSubmit } = useLogin();
    const navigate = useNavigate();
    const { accessToken } = useOidcAccessToken();

    useEffect(() => {
        if (accessToken) {
            const decodedToken = jwtDecode<MyNewJwtPayload>(accessToken);
            let userRole = decodedToken.roles as any;
            if (
                !userRole ||
                (typeof userRole === 'string' && userRole.trim() === "") ||
                (Array.isArray(userRole) && userRole.length === 0)
            ) {
                if (initialUser.rememberMe) {
                    localStorage.removeItem('token');
                } else {
                    sessionStorage.removeItem('token');
                }

                setSnackbarMessage('Invalid system user');
                setOpenNotification(true);

                setTimeout(() => {
                    navigate('/');
                }, 2000);

                return;
            }

            const newUserData = {
                name: decodedToken.name,
                email: decodedToken.preferred_username,
                expired: decodedToken.exp,
                role: decodedToken.roles,
                nbf: decodedToken.nbf,
                iat: decodedToken.iat,
                token: accessToken,
            };
            setUser(newUserData);

            if (initialUser.rememberMe) {
                localStorage.setItem('token', accessToken);
            } else {
                sessionStorage.setItem('token', accessToken);
            }

            navigate('/map-view');
        }
    }, [accessToken, setUser, navigate, initialUser.rememberMe]);

    return (
        <Box sx={boxStyle}>
            <Box sx={headerBoxStyle}>
                <NavLink to="/" style={navLinkStyle}>
                    <ArrowBackIos sx={iconStyle} />
                </NavLink>
                <Text variant='caption' sx={textStyle}>Sign In</Text>
            </Box>
            <Formik
                initialValues={initialUser}
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers: FormikHelpers<InitialUser>) => {
                    onSubmit(values, formikHelpers);
                }}
                enableReinitialize
            >
                {({ handleChange }) => (
                    <Form>
                        <Box sx={formBoxStyle}>
                            <Field
                                component={TextField}
                                name="username"
                                label="Username"
                                variant="filled"
                                fullWidth
                                sx={fieldStyle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setInitialUser((prev: InitialUser) => ({
                                        ...prev,
                                        username: e.target.value,
                                    }));
                                }}
                            />
                            <Field
                                component={TextField}
                                name="password"
                                label="Password"
                                variant="filled"
                                fullWidth
                                type="password"
                                sx={fieldStyle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setInitialUser((prev: InitialUser) => ({
                                        ...prev,
                                        password: e.target.value,
                                    }));
                                }}
                            />
                            <Box sx={actionBoxStyle}>
                                <Box>
                                    <Field
                                        type="checkbox"
                                        name="rememberMe"
                                        as={CustomCheckbox}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleChange(e);
                                            setInitialUser((prev: InitialUser) => ({
                                                ...prev,
                                                rememberMe: e.target.checked,
                                            }));
                                        }}
                                    />
                                    <Text variant='caption'>Remember Me</Text>
                                </Box>
                                <ButtonWithIcon
                                    variant='contained'
                                    type='submit'
                                    icon={null}
                                    text='Sign In'
                                    disabled={loading}
                                    sx={buttonStyle}
                                />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <ButtonWithIcon
                                    variant='contained'
                                    onClick={OidcLogin}
                                    icon={<Microsoft />}
                                    text='Microsoft Account'
                                    sx={microsoftButton}
                                />
                            </Box>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}

export default LoginForm;
