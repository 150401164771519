import { DataRow, SortConfig } from '../components/layout/event-content/EventTable';

export const handleChangePage = (
    setPage: (val: number) => void
) => (event: unknown, newPage: number) => {
    setPage(newPage);
};

export const handleChangeRowsPerPage = (
    setRowsPerPage: (val: number) => void,
    setPage: (val: number) => void
) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

export const handleEditEvent = (
    id: number,
    events: DataRow[],
    setTempData: React.Dispatch<React.SetStateAction<DataRow | null>>,
    setEditingId: React.Dispatch<React.SetStateAction<number | null>>
) => {
    const currentRowData = events.find((row: DataRow) => row.id === id);
    setTempData(currentRowData ? { ...currentRowData } : null);
    setEditingId(id);
};

export const handleSaveEvent = async (
    id: number,
    events: DataRow[],
    saveEvent: (id: number, eventToUpdate: DataRow) => void,
    setEditingId: React.Dispatch<React.SetStateAction<number | null>>,
    setTempData: React.Dispatch<React.SetStateAction<DataRow | null>>
) => {
    const eventToUpdate = events.find((event: DataRow) => event.id === id);

    if (!eventToUpdate) {
        console.error("No event found with id:", id);
        return;
    }

    try {
        saveEvent(id, eventToUpdate);
        setEditingId(null);
        setTempData(null);
    } catch (error) {
        setEditingId(null);
        setTempData(null);
    }
};

export const handleCancelEvent = (
    tempData: DataRow | null,
    editingId: number | null,
    setEvents: React.Dispatch<React.SetStateAction<DataRow[]>>,
    setEditingId: React.Dispatch<React.SetStateAction<number | null>>,
    setTempData: React.Dispatch<React.SetStateAction<DataRow | null>>
) => {
    if (tempData && editingId !== null) {
        setEvents((currentData: DataRow[]) =>
            currentData.map((row: DataRow) => row.id === editingId ? { ...tempData } : row)
        );
        setEditingId(null);
        setTempData(null);
    }
};

export const handleChange = (
    id: number,
    field: keyof DataRow,
    inputValue: string,
    setEvents: React.Dispatch<React.SetStateAction<DataRow[]>>
) => {
    setEvents((currentData: DataRow[]) =>
        currentData.map((row: DataRow) => {
            if (row.id === id) {
                return { ...row, [field]: inputValue };
            }
            return row;
        })
    );
};

export const filteredData = (
    events: DataRow[],
    eventFilter: string,
    filterType: string | null,
    filterStatus: string | null,
    filterDescription: string,
    filterId: string,
    filterRemarks: string,
    filterAssignedTo: string | null,
    filterCreatedBy: string,
    filterStartedDateTime: string,
    filterReportedDateTime: string,
    filterCompletedDateTime: string
): DataRow[] => {
    return events.filter((item) => {
        const matchesFilter = Object.values(item).some((value) =>
            String(value).toLowerCase().includes(eventFilter.toLowerCase())
        );

        const matchesType = filterType !== null ? item.type === parseInt(filterType) : true;
        const matchesStatus = filterStatus !== null ? item.status === parseInt(filterStatus) : true;
        const matchesDescription = filterDescription ? item.description.toLowerCase().includes(filterDescription.toLowerCase()) : true;
        const matchesId = filterId ? item.id.toString().includes(filterId) : true;
        const matchesRemarks = filterRemarks ? item.remarks.toLowerCase().includes(filterRemarks.toLowerCase()) : true;
        const matchesAssignedTo = filterAssignedTo !== null ? item.assignedTo === filterAssignedTo : true;
        const matchesCreatedBy = filterCreatedBy ? item.createdBy.toLowerCase().includes(filterCreatedBy.toLowerCase()) : true;
        const matchesStartedDateTime = filterStartedDateTime ? new Date(item.startedDateTime).toLocaleDateString() === new Date(filterStartedDateTime).toLocaleDateString() : true;
        const matchesReportedDateTime = filterReportedDateTime ? new Date(item.reportedDateTime).toLocaleDateString() === new Date(filterReportedDateTime).toLocaleDateString() : true;
        const matchesCompletedDateTime = filterCompletedDateTime ? new Date(item.completedDateTime).toLocaleDateString() === new Date(filterCompletedDateTime).toLocaleDateString() : true;

        return matchesFilter && matchesType && matchesStatus && matchesDescription && matchesId && matchesRemarks && matchesAssignedTo && matchesCreatedBy && matchesStartedDateTime && matchesReportedDateTime && matchesCompletedDateTime;
    });
};

export const sortedData = (
    events: DataRow[],
    eventFilter: string,
    sortConfig: SortConfig,
    page: number,
    rowsPerPage: number,
    filterType: string | null,
    filterStatus: string | null,
    filterDescription: string,
    filterId: string,
    filterRemarks: string,
    filterAssignedTo: string | null,
    filterCreatedBy: string,
    filterStartedDateTime: string,
    filterReportedDateTime: string,
    filterCompletedDateTime: string
): DataRow[] => {
    const filteredItems = filteredData(events, eventFilter, filterType, filterStatus, filterDescription, filterId, filterRemarks, filterAssignedTo, filterCreatedBy, filterStartedDateTime, filterReportedDateTime, filterCompletedDateTime);
    let sortableItems = [...filteredItems];
    if (sortConfig.key !== null) {
        const sortKey = sortConfig.key as keyof DataRow;
        sortableItems.sort((a, b) => {
            if (a[sortKey] < b[sortKey]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortKey] > b[sortKey]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    }
    return sortableItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};

export const renderDisplayValue = (value: any, formatter?: (value: any) => string) => {
    return formatter ? formatter(value) : value;
};