import React from 'react';
import { useDocumentView } from '../../../context/document-context/DocumentContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton } from '@mui/material';
import DeleteConfirmation from './DeleteConfirmation';
import FileRow from './FileRow';
import DirectoryRow from './DirectoryRow';

interface Directory {
    name: string;
    fileSize: number;
    modifiedDateTime: string;
    subDirectories?: Directory[];
    files?: File[];
}

interface File {
    id: string;
    name: string;
    fileSize: number;
    modifiedDateTime: string;
    isOnline?: boolean;
}

const DocumentListView: React.FC = () => {

    const { documentList, loading, path, setPath, downLoadDocument, renameDocument } = useDocumentView();
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<boolean>(false);
    const [fileId, setFileId] = React.useState<string | null>(null);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [editFileId, setEditFileId] = React.useState<string | null>(null);
    const [editFileName, setEditFileName] = React.useState<string>('');

    const handleEditClick = (file: File) => {
        setEditing(true);
        setEditFileId(file.id);
        setEditFileName(file.name);
    };

    const handleSaveClick = () => {
        if (editFileId) {
            renameDocument(editFileId, editFileName);
        }
        setEditing(false);
        setEditFileId(null);
        setEditFileName('');
    };

    const handleCancelClick = () => {
        setEditing(false);
        setEditFileId(null);
        setEditFileName('');
    };

    const handleClickClearButton = (id: string) => {
        setOpenDeleteConfirmation(true);
        setFileId(id);
    };

    const renderSubDirectoryRows = (directories: Directory[] = [], level = 0): JSX.Element[] => {
        if (!directories?.length) {
            return [
                <TableRow key="no-data">
                    <TableCell colSpan={4} align="center">
                        No data available
                    </TableCell>
                </TableRow>,
            ];
        }

        return directories.reduce<JSX.Element[]>((acc, directory) => {
            const hasSubDirectories = directory.subDirectories && directory.subDirectories.length;
            const hasFiles = directory.files && directory.files.length;

            if (hasSubDirectories) {
                acc.push(
                    ...directory.subDirectories!.map((subDir, index) => (
                        <DirectoryRow
                            key={`${level}-dir-${index}`}
                            subDir={subDir}
                            level={level}
                            path={path}
                            setPath={setPath}
                        />
                    )),
                );
                acc.push(...renderSubDirectoryRows(directory.subDirectories, level + 1));
            }

            if (hasFiles) {
                acc.push(
                    ...directory.files!.map((file, index) => (
                        <FileRow
                            key={`${level}-file-${index}`}
                            file={file}
                            editing={editing}
                            editFileId={editFileId}
                            editFileName={editFileName}
                            setEditFileName={setEditFileName}
                            handleSaveClick={handleSaveClick}
                            handleCancelClick={handleCancelClick}
                            handleEditClick={handleEditClick}
                            handleClickClearButton={handleClickClearButton}
                            downLoadDocument={downLoadDocument}
                        />
                    )),
                );
            }

            if (!hasSubDirectories && !hasFiles && level === 0) {
                acc.push(
                    <TableRow key="no-data">
                        <TableCell colSpan={4} align="center">
                            No data available
                        </TableCell>
                    </TableRow>,
                );
            }

            return acc;
        }, []);
    };

    const renderSkeleton = (): JSX.Element[] => {
        return Array.from(new Array(5)).map((_, index) => (
            <TableRow key={index}>
                <TableCell>
                    <Skeleton variant="text" width="100%" />
                </TableCell>
                <TableCell>
                    <Skeleton variant="text" width="100%" />
                </TableCell>
                <TableCell>
                    <Skeleton variant="text" width="100%" />
                </TableCell>
                <TableCell>
                    <Skeleton variant="text" width="100%" />
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <React.Fragment>
            <TableContainer component={Paper} sx={{ overflowY: 'auto', maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>Size</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>Modified</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            renderSkeleton()
                        ) : (
                            renderSubDirectoryRows(documentList?.directories)
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <DeleteConfirmation
                fileId={fileId}
                openDialog={openDeleteConfirmation}
                setOpenDialog={setOpenDeleteConfirmation}
            />
        </React.Fragment>
    );
};

export default DocumentListView;
