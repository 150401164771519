import React, { createContext, useContext, ReactNode, useState, useEffect, useRef } from 'react';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useOidc } from '@axa-fr/react-oidc';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  baseUrl: string;
  initialUser: InitialUser;
  setInitialUser: (val: any) => void;
  user: any | null;
  setUser: (val: any) => void;
  snackbarOpen: boolean;
  setSnackbarOpen: (val: boolean) => void;
  snackbarMessage: string;
  setSnackbarMessage: (val: string) => void;
  variant: string;
  setVariant: (val: string) => void;
  showDisclaimer: boolean;
  setShowDisclaimer: (val: boolean) => void;
  login: (username: string, password: string, rememberMe: boolean) => void;
  OidcLogin: () => void;
  logout: () => void;
  isTokenExpired: () => boolean;
  refreshToken: () => Promise<void>;
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: (val: boolean) => void;
  isLoggedIn: boolean;
  loading: boolean;
  openNotification: boolean;
  setOpenNotification: (val: boolean) => void;
}

export interface InitialUser {
  username: string;
  password: string;
  rememberMe: boolean
}

export interface MyNewJwtPayload extends JwtPayload {
  preferred_username: string;
  name: string;
  roles: string[];
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
}

export interface User {
  name: string;
  email: string;
  roles: string[];
  nbf: number;
  expired: number;
  iat: number;
  token: string;
  iss: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {

  const [initialUser, setInitialUser] = useState<InitialUser>({ username: '', password: '', rememberMe: false })
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [variant, setVariant] = useState<string>('success'); // 'error', 'warning', 'info', 'success'
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [isMobileMenuActive, setIsMobileMenuActive] = useState<boolean>(false);
  const tokenRefreshTimeout = useRef<NodeJS.Timeout | null>(null);
  const { logout: oidcLogout, login: oidcLogin } = useOidc();
  const [openNotification, setOpenNotification] = useState<boolean>(false);

  const navigate = useNavigate()

  useEffect(() => {
    const currentUrl = window.location.href;

    if (
      currentUrl.includes('localhost') ||
      currentUrl.includes('web-sfaims-dev.azurewebsites.net') ||
      currentUrl.includes('web-sfaims.azurewebsites.net')
    ) {
      setBaseUrl(process.env.REACT_APP_BASE_URL_DEV || '');
    } else if (currentUrl.includes('asn.infoatsea.com')) {
      setBaseUrl(process.env.REACT_APP_BASE_URL_PROD || '');
    }

    const rememberMe = !!localStorage.getItem('token');
    const token = rememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode<MyNewJwtPayload>(token);
      let userRole = decodedToken.roles as any;
      if (
        !userRole ||
        (typeof userRole === 'string' && userRole.trim() === "") ||
        (Array.isArray(userRole) && userRole.length === 0)
      ) {

        localStorage.clear()
        sessionStorage.clear()

        setSnackbarMessage('Invalid system user');
        setOpenNotification(true);

        setTimeout(() => {
          navigate('/');
        }, 2000);

        return;
      }
      if (Date.now() / 1000 < decodedToken.exp) {
        setUser({
          name: decodedToken.name,
          email: decodedToken.preferred_username,
          roles: decodedToken.roles,
          nbf: decodedToken.nbf,
          expired: decodedToken.exp,
          iat: decodedToken.iat,
          iss: decodedToken.iss,
          token: token,
        });
        setRefreshTokenTimeout(decodedToken.exp);
      } else {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
      }
    }
  }, []);


  useEffect(() => {
    if (user === null) {
      setIsLoggedIn(false);
    } else if (user !== null && !isLoggedIn) {
      setIsLoggedIn(true);
    }
  }, [user, isLoggedIn]);

  const login = async (username: string, password: string, rememberMe: boolean) => {
    if (!baseUrl) return;
    setLoading(true)
    try {
      const response = await fetch(`${baseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (!response.ok) {
        throw new Error('Login failed');
      }
      const data = await response.json();
      if (rememberMe) {
        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
      } else {
        sessionStorage.setItem('token', data.accessToken);
        sessionStorage.setItem('refreshToken', data.refreshToken);
      }
      const decodedToken = jwtDecode<MyNewJwtPayload>(data.accessToken);
      let userRole = decodedToken.roles as any;
      if (
        !userRole ||
        (typeof userRole === 'string' && userRole.trim() === "") ||
        (Array.isArray(userRole) && userRole.length === 0)
      ) {

        localStorage.clear()
        sessionStorage.clear()

        setSnackbarMessage('Invalid system user');
        setOpenNotification(true);

        setTimeout(() => {
          navigate('/');
        }, 2000);

        return;
      }
      const newUserData = {
        name: decodedToken.name,
        email: decodedToken.preferred_username,
        roles: decodedToken.roles,
        nbf: decodedToken.nbf,
        expired: decodedToken.exp,
        iat: decodedToken.iat,
        iss: decodedToken.iss,
        token: data.accessToken,
      };
      setUser(newUserData);
      setRefreshTokenTimeout(decodedToken.exp);
    } catch (error) {
      setSnackbarMessage(`${error}`);
      setVariant('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false)
    }
  };

  const OidcLogin = async () => {
    try {
      await oidcLogin();
    } catch (error) {
      console.error('OIDC Login failed:', error);
    }
  };

  const logout = () => {
    const clearStorage = () => {
      sessionStorage.clear();
      localStorage.clear();
    };

    if (user?.iss === "IMS Auth Server") {
      clearStorage();
      setTimeout(() => {
        setUser(null);
        setIsLoggedIn(false);
      }, 0);
    } else {
      clearStorage();
      oidcLogout();
    }
  };

  const isTokenExpired = (): boolean => {
    const rememberMe = !!localStorage.getItem('token');
    const token = rememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode<MyNewJwtPayload>(token);
      const currentTime = Date.now() / 1000;
      return currentTime > decodedToken.exp;
    }
    return true;
  };

  const refreshToken = async () => {
    const rememberMe = !!localStorage.getItem('token');
    const token = rememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token');
    const refreshToken = rememberMe ? localStorage.getItem('refreshToken') : sessionStorage.getItem('refreshToken');
    if (!token || !refreshToken || !baseUrl) return;
    try {
      const response = await fetch(`${baseUrl}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ accessToken: token, refreshToken: refreshToken }),
      });
      if (!response.ok) {
        throw new Error('Token refresh failed');
      }
      const data = await response.json();
      if (rememberMe) {
        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
      } else {
        sessionStorage.setItem('token', data.accessToken);
        sessionStorage.setItem('refreshToken', data.refreshToken);
      }
      const decodedToken = jwtDecode<MyNewJwtPayload>(data.accessToken);
      const newUserData = {
        name: decodedToken.name,
        email: decodedToken.preferred_username,
        roles: decodedToken.roles,
        nbf: decodedToken.nbf,
        expired: decodedToken.exp,
        iat: decodedToken.iat,
        iss: decodedToken.iss,
        token: data.accessToken,
      };
      setUser(newUserData);
      setRefreshTokenTimeout(decodedToken.exp);
    } catch (error) {
      logout();
      setSnackbarMessage('Session expired. Please log in again.');
      setVariant('error');
      setSnackbarOpen(true);
    }
  };

  const setRefreshTokenTimeout = (tokenExp: number) => {
    if (tokenRefreshTimeout.current) {
      clearTimeout(tokenRefreshTimeout.current);
    }
    const currentTime = Date.now() / 1000;
    const delay = (tokenExp - currentTime - 60) * 1000;
    tokenRefreshTimeout.current = setTimeout(() => {
      refreshToken();
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (tokenRefreshTimeout.current) {
        clearTimeout(tokenRefreshTimeout.current);
      }
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        OidcLogin,
        logout,
        isLoggedIn,
        baseUrl,
        initialUser,
        setInitialUser,
        user,
        setUser,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        variant,
        setVariant,
        showDisclaimer,
        setShowDisclaimer,
        isTokenExpired,
        refreshToken,
        isMobileMenuActive,
        setIsMobileMenuActive,
        loading,
        openNotification,
        setOpenNotification
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
